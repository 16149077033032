

import Login from "../components/Contents/Login"
import { ConfigsAppProvider } from "../context/configsApp"
import PublicRoute from "../routes/PublicRoute"
export default function Index() {
    
    return (
        <ConfigsAppProvider>
            <PublicRoute>
                <Login />
            </PublicRoute>
        </ConfigsAppProvider>
        
    )
}
