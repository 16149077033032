import Head from 'next/head'
import { Form, Input, Button, Alert } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import * as Sentry from '@sentry/nextjs';
import React, { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import LoginRes from '../../../integration/rest/login/LoginRes'
import PublicRoute from '../../../routes/PublicRoute'
import { useConfigsApp } from '../../../context/configsApp';
export default function Login() {
    const router = useRouter()
    const [recaptcha, setrecaptcha] = useState(null)
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false)
    const {  setLoadingContentPage } = useConfigsApp()
    async function onFinish(values) {
        try {
            if (recaptcha == null) {
                alert('reCaptcha invalido ou não verificado, verifique antes de continua.')
                return
            }
            setloading(true)
            const response = await LoginRes.login({
                email: values.email.toLowerCase(),
                password: values.password,
                recaptcha: recaptcha
            });
            if(response?.error?.message){
                seterror(response?.error?.message)
                return
            }
            if (response?.data?.data?.token) {
                localStorage.setItem('castratoken', response?.data?.data?.token)
                setLoadingContentPage(true)
                router.push('/board')
                return
            }
            seterror('Não foi possivel acessar o servidor no momento, tente novamente mais tarde.')
 
        } catch (err) {
            console.log('error', err)
            seterror('Não foi possivel acessar o servidor no momento, tente novamente mais tarde.')
            Sentry.captureException(err);
        }finally{
            setloading(false)
        }
    };

    function onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    };
    function ValidaRecaptch(value) {
        console.log('value', value)
        setrecaptcha(value)
    }
    return (
        <PublicRoute>
            <Head>
                <title>Painel - CastraFácil</title>
                <meta name="description" content="Painel castrafacil" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <div className="limiter">
                <div className="container-login100">
                    <div className="divlogo">
                        <img className="logo" src="/logobranco.png" alt="logo" />
                    </div>
                    <div className="wrap-login100">
                        <Form
                            name="basic"
                            autoComplete="on"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            className="formLogin"
                        >
                            {error &&
                                <Alert
                                    showIcon
                                    message="Error"
                                    description={error}
                                    type="error"
                                    style={{ marginBottom: 10, maxWidth: 350}}
                                />
                            }
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor coloque seu email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Senha"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor coloque sua senha',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item>
                                <ReCAPTCHA
                                    size="normal"
                                    render="explicit"
                                    hl="pt-BR"
                                    style={{ width: 100 }}
                                    sitekey="6LeOa10eAAAAAAOlseHLAiSJft56vZuRePY6HlDf"
                                    onChange={ValidaRecaptch}
                                />
                            </Form.Item>
                            <Form.Item>
                                <span style={{ cursor: 'pointer' }} onClick={() => router.push('/recovery')}>Recuperar senha?</span>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block size="large" loading={loading}>
                                    Entrar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </PublicRoute>
    )
}
