

import Request from '../integration/rest/Request'
import { URL_API_PANEL } from '../constants/configs'

const buildResponse = (response) => ({
  error: (response?.error ?? false),
  data: response?.success,
})

const ApiFactory = {
  buildResponse: async (payload) => {
    const request = new Request()
    const response = await request.execute({
      method: payload.method,
      url: `${URL_API_PANEL}${payload.endpoint}`,
      body: payload.body,
      headers: payload.headers,
      contentJson: payload.contentJson,
    })
    return buildResponse(response)
  },
}

export default ApiFactory
