import React, {createContext, useContext, useState } from "react";
function useProviderValue() {
    const [ loadingContentPage, setLoadingContentPage ] = useState(true)

    return {
        loadingContentPage,
        setLoadingContentPage,
    }
}

const ConfigsAppContext = createContext(undefined)

export const ConfigsAppProvider = (props) =>{
    const value = useProviderValue()
  return <ConfigsAppContext.Provider value={value} {...props} />
}

export function useConfigsApp() {
    const context = useContext(ConfigsAppContext)
    if (context === undefined) {
      throw new Error(`useConfigsApp must be used within a ConfigsAppProvider`)
    }
    return context
  }


