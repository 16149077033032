import { useEffect, useState } from 'react'
import { useRouter} from 'next/router'
import LoadingContent from '../components/Contents/LoadingContent'
import { useConfigsApp } from '../context/configsApp'
export default function PublicRoute(props) {
    const router = useRouter()
    const { loadingContentPage, setLoadingContentPage } = useConfigsApp()
    useEffect(() => {
        const token = localStorage.getItem('castratoken')
        if (token) {
            router.push('/board')
            return
        }
        setLoadingContentPage(false)
    },[])

    if(loadingContentPage) return <LoadingContent />
    return <>{props.children}</>
}