
import React from 'react';
import Loading from '../Loading';

export default function LoadingContent() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px',height: "100vh" }}>
            <Loading />
        </div>
    )
}