import Http from './Http'

function BuildError(error) {
  const details = Array.isArray(error?.details?.errors)
    ? (error?.details?.errors[0])
    : undefined
  return {
    error: {
      message: details?.message ?? error.message,
      filds: details?.field ?? null,
    }
  }
}
export default class Request {
  constructor() {
    this.Http = new Http()
  }
  async execute({ method, url, body, headers, contentJson }) {
    try {
      const { status, data } = await this.Http.request({ method, url, body, headers, contentJson })
      if (status >= 200 && status < 300) {
        if (data) return data
      } else {
        if ('error' in data) return BuildError(data.error)
      }
      throw new Error('Serviço Indisponivel')
    } catch (error) {
      Logger.error(error)
      return BuildError(error)
    }
  }
}
