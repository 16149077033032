
import ApiPanelFactory from '../../../factories/ApiPanelFactory'
const  LoginRes = {
   login: async function(body) {
    return await ApiPanelFactory.buildResponse({
      method: 'POST',
      endpoint: `/login`,
      body: body,
    })
  }
}
export default LoginRes
