import https from 'https'

export default class Http {
  async request({ method, url, body, contentJson = true, headers = {} }) {
    const options = {
      method,
      headers,
      agent: new https.Agent({ rejectUnauthorized: false }),
    }
    if (contentJson) {
      options.headers = {
        'Content-Type': 'application/json',
        ...options.headers,
      }
    }
    if (body) {
      const bodyParse = contentJson ? JSON.stringify(body) : body
      Object.assign(options, { body: bodyParse })
    }
    const token = localStorage.getItem('castratoken')
    if (token) {
      Object.assign(options, {
        headers: { ...options.headers, Authorization: `Bearer ${token}` },
      })
    }
    const response = await fetch(url, options)
    const data = await response.json()
    return {
      status: response.status,
      data,
    }
  }
}
